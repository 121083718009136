

//---------------------------
//ヘッダーのディレクトリ判定
//---------------------------
let loc = window.location.pathname;
let dir = loc.substring(0, loc.lastIndexOf('/'));
//console.log(dir);

if (dir.match(/company/)) {
  $("#company").toggleClass("js-on-directory");
} else if(dir.match(/strength/)){
  $("#strength").toggleClass("js-on-directory");
} else if(dir.match(/about/)){
  $("#about").toggleClass("js-on-directory");
} else if(dir.match(/performance/)){
  $("#performance").toggleClass("js-on-directory");
} else if(dir.match(/recruit/)){
  $("#recruit").toggleClass("js-on-directory");
}