
import Swiper from 'swiper';


/*--------------------------------
ロード時起動
---------------------------------*/
window.addEventListener('load',()=>{
  let slidenum = $('#slide .c-slider-list li').length;
  //console.log(slidenum);
  if(slidenum > 1){
    createSwiper();
  }
},false);


/*------------------------------
施工実績スライド
-------------------------------*/
let mySwiper ;
const createSwiper = () =>{
  mySwiper = new Swiper ('#slide', {
      slidesPerView: 1,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      //slidesPerView: "auto",
      //loop: true,
      onSlideChangeEnd : (s) => {s.fixLoop();},
      //spaceBetween: 0,
      //loopedSlides: 1,
      //disableOnInteraction: true,
      //slideToClickedSlide: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true 
      }
  });
}


