
/*--------------------------
ロード時に実行
----------------------------*/

  let browserSize = window.innerWidth;
  if(browserSize >= 1200){
    let thisOffset;
    thisOffset = $('.l-header-inqury__inner').offset().top + $('.l-header-inqury__inner').outerHeight();
    thisOffset = thisOffset / 2;


    $(window).scroll(function () {
      if ($(window).scrollTop()  > thisOffset) {
        $('.l-header').addClass('js-fixnavi-on');
      } else {
        $('.l-header').removeClass('js-fixnavi-on');
      }
    });
  }
  
  if(browserSize < 1200) {
    let thisOffset_sp;
    thisOffset_sp = $('.l-header-inqury__inner').offset().top + $('.l-header-inqury__inner').outerHeight();
    thisOffset_sp = thisOffset_sp / 2;


    $(window).scroll(function () {
      if ($(window).scrollTop()  > thisOffset_sp) {
        $('.l-header').addClass('js-fixnavi-on-sp');
      } else {
        $('.l-header').removeClass('js-fixnavi-on-sp');
      }
    });
  }


/*--------------------------
リサイズ時に実行
----------------------------*/
let timeoutId ;
window.addEventListener( "resize", function () {
	clearTimeout( timeoutId ) ;

	timeoutId = setTimeout( function () {

      $(window).scroll(function () {
        let browserSize_re = window.innerWidth;
        if(browserSize_re >= 1200){
          let thisOffset_re;
          thisOffset_re = $('.l-header-inqury__inner').offset().top + $('.l-header-inqury__inner').outerHeight();
          thisOffset_re = thisOffset_re / 2;
          if ($(window).scrollTop()  > thisOffset_re) {
            $('.l-header').addClass('js-fixnavi-on');
          } else {
            $('.l-header').removeClass('js-fixnavi-on');
          }
        }else{
          $('.l-header').removeClass('js-fixnavi-on');
        }

        if(browserSize_re < 1200){
          let thisOffset_re_sp;
          thisOffset_re_sp = $('.l-header-inqury__inner').offset().top + $('.l-header-inqury__inner').outerHeight();
          thisOffset_re_sp = thisOffset_re_sp / 2;
          if ($(window).scrollTop()  > thisOffset_re_sp) {
            $('.l-header').addClass('js-fixnavi-on-sp');
          } else {
            $('.l-header').removeClass('js-fixnavi-on-sp');
          }
        }else{
          $('.l-header').removeClass('js-fixnavi-on-sp');
        }
      });
    
	}, 500);
});





